import { useStaticQuery, graphql } from "gatsby"

const usePlayBannerImages = () => {
  const bannerImages = useStaticQuery(graphql`
    {
      raj_banner: file(relativePath: { eq: "hero-raj.png" }) {
        childImageSharp {
          gatsbyImageData(width: 400, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }
      raj_banner_hero: file(relativePath: { eq: "hero-raj.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 500
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      raj_banner_fixed: file(relativePath: { eq: "hero-raj.png" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            layout: FIXED
            width: 400
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      macbeth_banner: file(relativePath: { eq: "hero-macbeth.png" }) {
        childImageSharp {
          gatsbyImageData(width: 400, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }
      macbeth_banner_hero: file(relativePath: { eq: "hero-macbeth.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 500
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      macbeth_banner_fixed: file(relativePath: { eq: "hero-macbeth.png" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            layout: FIXED
            width: 400
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      msnd_banner: file(relativePath: { eq: "hero-msnd.png" }) {
        childImageSharp {
          gatsbyImageData(width: 400, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }
      msnd_banner_hero: file(relativePath: { eq: "hero-msnd.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 500
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      msnd_banner_fixed: file(relativePath: { eq: "hero-msnd.png" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            layout: FIXED
            width: 400
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      hamlet_banner: file(relativePath: { eq: "hero-hamlet.png" }) {
        childImageSharp {
          gatsbyImageData(width: 400, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }
      hamlet_banner_hero: file(relativePath: { eq: "hero-hamlet.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 500
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      hamlet_banner_fixed: file(relativePath: { eq: "hero-hamlet.png" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            layout: FIXED
            width: 400
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      jc_banner: file(relativePath: { eq: "hero-jc.png" }) {
        childImageSharp {
          gatsbyImageData(width: 400, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }
      jc_banner_hero: file(relativePath: { eq: "hero-jc.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 500
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      jc_banner_fixed: file(relativePath: { eq: "hero-jc.png" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            layout: FIXED
            width: 400
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      product_features_macbeth: file(
        relativePath: { eq: "product-features-macbeth.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            layout: FIXED
            width: 364
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `)
  return bannerImages
}

export default usePlayBannerImages
