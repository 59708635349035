import React from "react"
import { useSelector } from "react-redux"

import { navigate } from "gatsby"

import usePlayBannerImages from "../hooks/useplaybannerimages"

import NoSubBanner from "../common/misc/nosubbanner"
import PageBanner from "../common/misc/pagebanner"

import { GatsbyImage, getImage } from "gatsby-plugin-image"

import QuizSVG from "../../scss/img/icon/sibsvg/sib-icon-star.svg"
import LessonPlanSVG from "../../scss/img/icon/sibsvg/sib-icon-whiteboard.svg"
import PlayTextSVG from "../../scss/img/icon/sibsvg/sib-icon-quill.svg"
import LockSVG from "../../scss/img/icon/sibsvg/sib-icon-lock.svg"

const UserHome = (props) => {
  var isUnpaid = props.subInfo && props.subInfo.isUnpaid
  const unpaidText = `Your trial account gives you access to select scenes. Subscribe
  for full access to all plays!`

  var userGreet = "Welcome!"

  if (props.subInfo) {
    userGreet = props.subInfo.userGreet
  }

  return (
    <>
      <PageBanner title="My Plays" userGreet={userGreet} />
      <div className="outer-keypage-container container py-0 mt-0 mb-4">
        <div className="columns my-0">
          <div className="column my-0 py-0">
            <NoSubBanner isUnpaid={isUnpaid} text={unpaidText} />
            <PlayListing {...props} isUnpaid={isUnpaid}></PlayListing>
          </div>
        </div>
      </div>
    </>
  )
}

const PlayListing = (props) => {
  const bannerImages = usePlayBannerImages()
  const playDefs = [
    {
      title: "Romeo & Juliet",
      playCode: "raj",
      blurb: ` 
          Immerse yourself in Shakespeare's timeless story of love,
          vengeance and tragedy - a tragic tale of two star-cross'd
          lovers whose untimely deaths finally unite two feuding
          families.
        `,
      bannerImage: getImage(bannerImages.raj_banner),
      firstKeypageId: "A001_S000_SX001",
    },
    {
      title: "Macbeth",
      playCode: "macbeth",
      blurb: ` 
          Read, watch and listen to one of Shakespeare's most
          widely-studied plays as Shakespeare In Bits brings to life
          the tragic tale of the murderous Macbeth in one complete
          experience.
        `,
      bannerImage: getImage(bannerImages.macbeth_banner),
      firstKeypageId: "A001_S001_SX001",
    },
    {
      title: "A Midsummer Night's Dream",
      playCode: "msnd",
      blurb: ` 
          A royal wedding in Athens sends four young lovers and a
          motley crew of amateur actors into a fairy-filled woodland
          where they fall under impish manipulation. Could this be
          real or is it a dream after all?    
        `,
      bannerImage: getImage(bannerImages.msnd_banner),
      firstKeypageId: "A001_S001_SX001",
    },
    {
      title: "Hamlet",
      playCode: "hamlet",
      blurb: ` 
          Experience one of Shakespeare's most powerful and
          influential tragedies as Shakespeare In Bits brings you on a
          journey of deception, revenge, treachery, indecision and
          moral decay through the fabled and tragic character of
          Hamlet.   
        `,
      bannerImage: getImage(bannerImages.hamlet_banner),
      firstKeypageId: "A001_S001_SX001",
    },
    {
      title: "Julius Caesar",
      playCode: "jc",
      blurb: ` 
          Travel back in time to ancient Rome and join the intrigue as
          a group of disgruntled citizens plot a conspiracy.
        `,
      bannerImage: getImage(bannerImages.jc_banner),
      firstKeypageId: "A001_S001_SX001",
    },
  ]

  const lastKps = useSelector((state) => state.app.lastKps)

  const gotoPlay = (e, playDef) => {
    e.preventDefault()
    let basePath = "/plays/" + playDef.playCode + "/"

    if (props.isUnpaid) {
      navigate(basePath + "scenes")
      return ""
    }

    if (lastKps[playDef.playCode] != null) {
      navigate(basePath + lastKps[playDef.playCode])
    } else {
      navigate(basePath + playDef.firstKeypageId)
    }
    return ""
  }

  return (
    <div className="block mt-1">
      {playDefs.map((playDef) => (
        <div
          className={`box play-home-listing-box my-3 py-0 has-background-sib${playDef.playCode}dark`}
          key={playDef.playCode}
        >
          <div
            className="columns my-0 play-home-listing-backing"
            key={playDef.playCode}
          >
            <div className="column my-0 py-0 is-4 is-hidden-mobile play-image-home-wrap">
              <GatsbyImage
                className="play-image-home"
                image={playDef.bannerImage}
                alt={playDef.title}
              />
            </div>
            <div className="column my-0 py-0 has-text-white">
              <h2 className="title has-text-white py-1 my-1 home-page-play-title">
                {playDef.title}
              </h2>
              <div className="is-size-7 pr-1">{playDef.blurb}</div>
              <div className="columns my-2">
                <div className="column is-9">
                  <button
                    className="button is-black is-fullwidth"
                    onClick={(e) => gotoPlay(e, playDef)}
                    onKeyDown={(e) => gotoPlay(e, playDef)}
                  >
                    Launch Play
                  </button>
                </div>
              </div>
            </div>
            <div className="column my-0 py-0">
              <PlayLinksTile
                subInfo={props.subInfo}
                playDef={playDef}
                isUnpaid={props.isUnpaid}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

const PlayLinksTile = (props) => {
  const isTeacher =
    props.subInfo &&
    props.subInfo.subInfo &&
    props.subInfo.subInfo.type === "teacher"


  const isStudent = 
    props.subInfo &&
    props.subInfo.isStudentCode

  const gotoTexts = (e) => {
    e.preventDefault()
    if (props.isUnpaid) {
      return
    }
    let path = "/plays/" + props.playDef.playCode + "/fulltext"
    navigate(path)
  }

  const gotoQuizzes = (e) => {
    e.preventDefault()
    let path = "/plays/" + props.playDef.playCode + "/quizzes"
    navigate(path)
  }

  const gotoLessonPlans = (e) => {
    e.preventDefault()
    if (props.isUnpaid) {
      return
    }
    let path = "/plays/" + props.playDef.playCode + "/lessonplans"
    navigate(path)
  }

  return (
    <>
      <div
        aria-hidden="true"
        onClick={(e) => gotoQuizzes(e)}
        onKeyDown={(e) => gotoQuizzes(e)}
        className="play-home-tile-hover"
      >
        <div
          className={`block box py-2 my-1 ${
            props.playDef.playCode === "hamlet" ||
            props.playDef.playCode === "jc"
              ? "is-hidden"
              : ""
          }`}
        >
          <div className="columns is-vcentered is-mobile">
            <div className="column is-2">
              <QuizSVG
                fill={`#ff3a00`}
                style={{ position: "relative", bottom: "-0.08rem" }}
              />
            </div>
            <div className="column has-text-weight-bold">Quizzes</div>
            <div className="column is-2">
              <span className="icon has-text-sibgrey4base">
                <i className="fas fa-arrow-right "></i>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div
        aria-hidden="true"
        onClick={(e) => gotoTexts(e)}
        onKeyDown={(e) => gotoTexts(e)}
        className={`${props.isUnpaid ? "" : "play-home-tile-hover"}`}
      >
        <div
          className={`block box py-2 ${
            props.playDef.playCode === "hamlet" ||
            props.playDef.playCode === "jc"
              ? "my-2"
              : "my-1"
          }
          ${props.isUnpaid ? "has-background-sibgrey5dark" : ""}
          `}
        >
          <div className="columns is-vcentered is-mobile">
            <div className="column is-2">
              <PlayTextSVG
                fill={`#ff3a00`}
                style={{ position: "relative", bottom: "-0.1rem" }}
                className={`${props.isUnpaid ? "is-hidden" : ""}`}
              />
              <LockSVG
                fill={`#606060`}
                style={{ position: "relative", bottom: "-0.1rem" }}
                className={`${props.isUnpaid ? "" : "is-hidden"}`}
              />
            </div>
            <div
              className={`column has-text-weight-bold
              ${props.isUnpaid ? "has-text-sibgrey6darker" : ""} 
            
            `}
            >
              Play Text
            </div>
            <div className="column is-2">
              <span
                className={`icon has-text-sibgrey4base
                ${props.isUnpaid ? "is-hidden" : ""}
              `}
              >
                <i className="fas fa-arrow-right "></i>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${isTeacher || isStudent ? "" : "is-hidden"} 
        ${
          props.playDef.playCode === "hamlet" ||
          props.playDef.playCode === "jc"
            ? "is-hidden"
            : ""
        }
        ${
          props.isUnpaid ? "" : "play-home-tile-hover"
        }`}
        aria-hidden="true"
        onClick={(e) => gotoLessonPlans(e)}
        onKeyDown={(e) => gotoLessonPlans(e)}
      >
        <div
          className={`block box py-2 ${isTeacher || isStudent ? "" : "is-hidden"}
               ${props.isUnpaid ? "has-background-sibgrey5dark" : ""} 
      `}
        >
          <div className="columns is-vcentered is-mobile">
            <div className="column is-2">
              <LessonPlanSVG
                fill={`#ff3a00`}
                className={`${props.isUnpaid ? "is-hidden" : ""}`}
                style={{ position: "relative", bottom: "-0.2rem" }}
              />
              <LockSVG
                fill={`#606060`}
                style={{ position: "relative", bottom: "-0.1rem" }}
                className={`${props.isUnpaid ? "" : "is-hidden"}`}
              />
            </div>
            <div
              className={`column has-text-weight-bold
              ${props.isUnpaid ? "has-text-sibgrey6darker" : ""} 
          
          `}
            >
              Lesson Plans
            </div>
            <div className="column is-2">
              <span
                className={`icon has-text-sibgrey4base
                ${props.isUnpaid ? "is-hidden" : ""}
            `}
              >
                <i className="fas fa-arrow-right "></i>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default UserHome
