import React from "react"

import Layout from "../components/common/layout/layout"
import Seo from "../components/common/layout/seo"

//import "../styles/mystyles.scss"

import PrivateContent from "../components/user/privatecontent"
import UserHome from "../components/home/home"

const StudentHome = props => {
  return (
    <Layout>
      <Seo title="Shakespeare In Bits - Home" />
      <PrivateContent accessLevel={"student"}>
        <UserHome subInfo={props.subInfo} />
      </PrivateContent>
    </Layout>
  )
}

export default StudentHome
